import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DataService } from '@core/services/data.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ClaimDetailsForm, ClaimDetailsLabelsType } from '../models/claim-details-form';
import { Observable, of } from 'rxjs';
import { SelectOptionType } from '@shared/models/select-options.model';
import { OutcomesStateType } from '../models/outcome-state';
import { OutcomeService } from '@api/services';
import { DocumentsDataService } from '@shared/components/documents/services/documents-data.service';
import { CaseNotesDataService } from '@shared/components/case-notes/services/case-notes-data.service';

import { AssessmentDataModel, OutcomeClaimModel, OutcomeClaimOutcomeStatusHistoryModel } from '@api/models';
import { Globals } from 'src/app/globals';
import { map, tap } from 'rxjs/operators';

import { LoggingService } from '@core/services/logging.service';
import { Cf2Store } from '@core/models/store.model';
import { errorMapFn } from '@core/models/cf2-validators.model';

import * as R from 'remeda';
import { TranslocoService } from '@ngneat/transloco';
import { parseDateFormattedFromDate } from '@shared/models/date-formats.model';
import { UserStateService } from '@core/services/user-state.service';
import { MonitoringPhaseDataService } from './monitoring-phase-data.service';
import { DateFieldTopComponent } from '@shared/components/label-top/date-field-top/date-field-top.component';

@Injectable({
  providedIn: 'root',
})
export class OutcomesDataService extends Cf2Store {
  private _isLoading = false;
  private _siteKey: number = null;
  private _ocStatusHistory: OutcomeClaimOutcomeStatusHistoryModel = null;

  get isReady() {
    return !this._isLoading;
  }

  get isStartClaim() {
    return !this.outcome?.claimTypeCode || this.outcome?.claimTypeCode === 'START'
  }

  private _outcome: OutcomeClaimModel = null;
  set outcome(outcome: OutcomeClaimModel) {
    if (!outcome) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.outcome has no outcome set` });
    }

    this.outcomeKey = outcome?.parentOutcomeClaimKey;
    this._outcomeResult = outcome?.outcomeResultCode;
    this._siteKey = outcome?.parentSiteKey;
    this._scheduledStartDate = outcome?.scheduledStartDate;

    this._outcome = outcome;
  }
  get outcome() {
    return this._outcome;
  }

  private _canShowCreateQuestionnaire = false;
  set showCreateQuestionnaire(bool: boolean) {
    this._canShowCreateQuestionnaire = bool;
  }
  get showCreateQuestionnaire() {
    return this._canShowCreateQuestionnaire;
  }

  private _outcomeResult: string = null;
  set outComeResult(ocResult: string) {
    this._outcomeResult = ocResult;
  }
  get outcomeResult() {
    return this._outcomeResult;
  }

  private _outcomeResultOnLoad: string = null;
  set outcomeResultOnLoad(ocResultOnLoad: string) {
    this._outcomeResultOnLoad = ocResultOnLoad;
  }
  get outcomeResultOnLoad() {
    return this._outcomeResultOnLoad;
  }

  private _eapPlanStart: string = null
  set eapPlanStartDate(planStart: string) {
    this._eapPlanStart = planStart;
  }
  get eapPlanStartDate() {
    return this._eapPlanStart;
  }

  private _scheduledStartDate: Date;
  set scheduledStartDate(scheduledStart: Date) {
    this._scheduledStartDate = scheduledStart;
  }
  get scheduledStartDate() {
    return this._scheduledStartDate;
  }

  get q16CutOffDate() {
    return this.globals.q16CutOffDate;
  }

  getMonitoringChecks$() {
    return this.outcomeSvc.apiOutcomeMyMonitoringChecksGet$Json();
  }

  getOutcomeClaim$(parentOutcomeClaimKey: number) {
    return this.outcomeSvc.apiOutcomeParentOutcomeClaimKeyGet$Json({ parentOutcomeClaimKey });
  }

  getOutcomesList$(parentMonitoringPhaseKey: number) {
    if (!parentMonitoringPhaseKey) {
      this.logSvc.logError({ lvl: 'WARN', mssg: 'no outcome key set in outcomesDataSvc.outcome$' });
    }
    return this.outcomeSvc.apiOutcomeGet$Json({ parentCaseKey: this.caseKey });
  }

  toggleLoading() {
    this._isLoading = !this._isLoading;
  }

  get outcomeKey() {
    const outcomeKey = this.globals.outcomeKey;
    if (!outcomeKey) {
      this.logSvc.logError({ lvl: 'WARN', mssg: 'no outcomeKey set in globals ' });
    }
    return outcomeKey;
  }

  set outcomeKey(key: number) {
    this.globals.outcomeKey = key;
  }

  private _state: string;

  private _assessments: AssessmentDataModel[];

  get assessments$() {
    const outcomeKey = this.outcomeKey ? this.outcomeKey : this.outcome ? this.outcome.outcomeClaimKey : null;
    if (!outcomeKey) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.questionaire has no outcome key set.` });
    }
    return this.outcomeSvc
      .apiOutcomeParentOutcomeClaimKeyAssessmentsGet$Json({
        parentOutcomeClaimKey: outcomeKey,
      })
      .pipe(tap((qs) => (this.assessments = qs)));
  }

  set assessments(ass: AssessmentDataModel[]) {
    this._assessments = ass;
  }
  get assessments() {
    return this._assessments;
  }

  get workflowHistory$() {
    return this.outcomeSvc.apiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGet$Json({
      parentOutcomeClaimKey: this.outcomeKey,
    });
  }

  get hasAssessments() {
    /*
      determine if the outcome already has an assessment with the following conditions: 
      1) if the outcome result is not yet defined - do not show the create questionnaire button
      2) if the outcome result is changed - display the create questionnaire button again
      3) if the outcome result is the same (and already has a questionnaire) - do not show the create questionnaire button
      */
    if (this.outcomeResult === null) return true;

    if ((this.outcomeResult != this.outcomeResultOnLoad)) return false;

    return this.hasUnknownOutcomeResult || this?.assessments?.length > 0;
  }

  get hasCaseNotes() {
    return !this.hasUnknownOutcomeResult || (this.hasUnknownOutcomeResult && this?.caseNotesSvc?.hasCaseNotes());
  }

  get isFinalized() {
    return this?._outcome?.outcomeStatusCode === 'FINAL';
  }

  get caseKey() {
    return this.globals.caseKey;
  }

  private _labels: ClaimDetailsLabelsType = null;
  set labels(labels: ClaimDetailsLabelsType) {
    this._labels = labels;
  }
  get labels() {
    return this._labels;
  }

  private _fg: UntypedFormGroup = null;
  get fg(): UntypedFormGroup {
    return this._fg;
  }
  set fg(value: UntypedFormGroup) {
    this._fg = value;
  }

  private _disabled: ClaimDetailsLabelsType = null;
  get disabled(): ClaimDetailsLabelsType {
    return this._disabled;
  }
  set disabled(value: ClaimDetailsLabelsType) {
    this._disabled = value;
  }

  private _required: ClaimDetailsLabelsType = null;
  get required(): ClaimDetailsLabelsType {
    return this._required;
  }
  set required(value: ClaimDetailsLabelsType) {
    this._required = value;
  }

  get siteKey() {
    return this.globals.siteKey;
  }

  get outcomeStatus() {
    return this?._outcome?.outcomeStatusDescription || null;
  }

  get roleCode() {
    return this?.globals?.roleCode;
  }

  get canSubmit() {
    const canSubmitRole = ['EC', 'SM'];
    const role = this?.globals?.roleCode;
    const canSubmit = canSubmitRole.includes(role);
    return canSubmit
      ? this._outcome?.outcomeStatusCode === 'INPR' ||
      this._outcome?.outcomeStatusCode === 'NOT_START' ||
      this._outcome?.outcomeStatusCode === 'RETN'
      : false;
  }

  get canReopen() {
    return this.roleCode === 'SCM' && this?._outcome?.outcomeStatusCode === 'FINAL';
  }

  get isReadOnly() {
    if (this.roleCode === 'HD') {
      return false;
    }
    return (
      this.globals.viewType === 'VIEW' || (this?._outcome?.outcomeStatusCode === 'FINAL' && (this.roleCode !== 'SCM' && this.roleCode !== 'SCO'))
      && this?.hasApprovalStatusInfo
    );
  }

  get hasApprovalStatusInfo() {
    return !!this?._outcome?.camsApprovedByRejectedBy
      || !!this?._outcome?.camsDateApprovedRejected
      || !!this?._outcome?.camsRejectComments
      || !!this?._outcome?.camsResubmitApprovedByRejectedBy
      || !!this?._outcome?.camsResubmitDateApprovedRejected
      || !!this?._outcome?.camsResubmitRejectComments
      || !!this?._outcome?.camsResubmitStatus
      || !!this?._outcome?.camsSsmSubmitionStatus
  }

  /**
   * Opts for claimTypeCode
   *
   * @type {Observable<SelectOptionType>}
   * @memberof OutcomesDataService
   */

  claimTypeCodeOpts$: Observable<SelectOptionType[]> = this.dataSvc
    .lookupRecords('ClaimType')
    .pipe(map((opts) => DataService.lookupToOptions(opts, this.translocoService.getActiveLang())));

  /**
   * Claim status code options - for claim details & filters
   *
   * @memberof OutcomesDataService
   */
  claimStatusCodesOpts$ = this.dataSvc
    .lookupRecords('ClaimStatusType')
    .pipe(map((opts) => DataService.lookupToOptions(opts, this.translocoService.getActiveLang())));

  getOrganizations() {
    return this.dataSvc.getOrganizations();
  }

  /**
   * Sites list for all-outcomes
   *
   * @memberof OutcomesDataService
   */
  getSites() {
    return this.dataSvc.getOrganizations().pipe(
      map((orgs) => [
        orgs,
        R.flattenDeep(
          orgs.map((org) =>
            org.sites.map((site) => ({
              value: site.parentSiteKey,
              description: `${org.organizationName} - ${site.siteName}`,
            }))
          )
        ),
      ])
    );
  }

  getEmployeeConsultants() {
    return this.dataSvc.getEmployeeConsultants();
  }

  /**
   * Opts for outcome results
   *
   * @type {Observable<SelectOptionType>}
   * @memberof OutcomesDataService
   */
  outcomeResultCodeOpts$: Observable<SelectOptionType[]> = this.dataSvc
    .lookupRecords('OutcomeResultType')
    .pipe(map((opts) => DataService.lookupToOptions(opts, this.translocoService.getActiveLang())));

  /**
   * assignToParentEmployeeKey Opts
   *
   * @type {Observable<SelectOptionType>}
   * @memberof OutcomesDataService
   */
  assignToParentEmployeeKeyOpts$(): Observable<SelectOptionType[]> {
    return this.globals?.caseOrgKey
      ? this.dataSvc.getOrgEmployees(this.globals.caseOrgKey).pipe(
        map((res) =>
          res.map((emp) => ({
            value: emp.parentEmployeeKey,
            description: `${emp?.firstName ? emp.firstName : ''} ${emp?.lastName ? emp.lastName : ''}`,
          }))
        )
      )
      : of([]);
  }

  /**
   * startReasonCode Opts - claim details
   *
   * @type {Observable<SelectOptionType>}
   * @memberof OutcomesDataService
   */
  startReasonCodeOpts$: Observable<SelectOptionType[]> = this.dataSvc
    .lookupRecords('StartReasonType')
    .pipe(map((opts) => DataService.lookupToOptions(opts, this.translocoService.getActiveLang())));

  /**
   * outcomeStatus Opts - claim details and all outcomes filter
   *
   * @type {Observable<SelectOptionType>}
   * @memberof OutcomesDataService
   */
  outcomeStatusCodeOpts$: Observable<SelectOptionType[]> = this.dataSvc
    .lookupRecords('OutcomeStatusType')
    .pipe(map((opts) => DataService.lookupToOptions(opts, this.translocoService.getActiveLang())));

  private get formValue() {
    const value = this._fg.getRawValue();
    return { ...value };
  }

  get formValid() {
    return this?._fg?.valid || false;
  }

  get hasOutcome() {
    return !!this._outcome;
  }

  get hasUnknownOutcomeResult() {
    return this?._outcomeResult === 'UNK';
  }

  constructor(
    private fb: RxFormBuilder,
    private dataSvc: DataService,
    private outcomeSvc: OutcomeService,
    private globals: Globals,
    private documentSvc: DocumentsDataService,
    private caseNotesSvc: CaseNotesDataService,
    private logSvc: LoggingService,
    private translocoService: TranslocoService,
    private userStateSvc: UserStateService
  ) {
    super();
  }

  sanitizeData() {
    this._labels = null;
    this._disabled = null;
    this._fg = null;
    this._required = null;
    this._outcome = null;
    this.outcomeKey = null;
  }

  initForms(state: OutcomesStateType = 'create') {
    const outcome = this._outcome;
    if (!outcome) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.initForms() has no outcome passed` });
    }
    const fields = new ClaimDetailsForm(this.fb, this.translocoService, { value: outcome });
    const { required, disabled, labels, fg } = fields;
    this._state = state;

    this.fg = fg;
    this.labels = labels;
    this.required = required;
    this.disabled = disabled;
  }
  /* put logic to upload document here */

  /* {{baseUrl}}/api/Outcome/:parentOutcomeKey/upload?documentSectionCode=<string>&documentType=<string>&comment=<string>&documentDate=<string> */
  async documentActions(parentOutcomeClaimKey: number) {
    return this.documentSvc.submitParentOutcomeClaimDocuments(parentOutcomeClaimKey);
  }
  /* {{baseUrl}}/api/Outcome/:parentOutcomeKey/link/:parentDocumentStoreKey */
  caseNoteActions(parentOutcomeClaimKey: number) {
    return this.caseNotesSvc.submitParentOutcomeClaimCaseNotes(parentOutcomeClaimKey);
  }

  async save() {
    const outcome = this._outcome;

    const { outcomeStatusCode } = outcome;
    const isNotStarted = outcomeStatusCode === 'NOT_START';

    const formValue = this.formValue;
    const oldOutcome = R.merge(outcome, { outcomeStatusCode: isNotStarted ? 'INPR' : outcomeStatusCode });

    const body = R.merge(oldOutcome, formValue);

    try {
      const docRes = await this.documentActions(outcome.parentOutcomeClaimKey);
      const noteRes = await this.caseNoteActions(outcome.parentOutcomeClaimKey); 
      const res = await this.outcomeSvc.apiOutcomePost$Json$Response({ body }).toPromise();

      // if (this.outcomeResult !== this.outcomeResultOnLoad) {
      //   await this.outcomeSvc.apiOutcomeParentOutcomeClaimKeyAssessmentsDelete$Json({ 
      //     parentOutcomeClaimKey: outcome.parentOutcomeClaimKey 
      //   });
      // }

      return res;
    } catch (err) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.save() failed with: ${err.message}` });
    }

    /* TODO: if it's a create state then we also need to submit the link table after the outcome result */
  }

  async submit() {
    const parentOutcomeClaimKey = this.outcomeKey;
    try {
      const res = await this.outcomeSvc
        .apiOutcomeParentOutcomeClaimKeySubmitPost$Response({ parentOutcomeClaimKey })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.submit() failed with: ${error.message}` });
    }
  }

  async approve(comments?: string) {
    const parentOutcomeClaimKey = this.outcomeKey;
    try {
      const res = await this.outcomeSvc
        .apiOutcomeParentOutcomeClaimKeyApprovePost$Response({ parentOutcomeClaimKey, body: { comment: comments } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.approve() failed with: ${error.message}` });
    }
  }

  async finalize(comments?: string) {
    const parentOutcomeClaimKey = this.outcomeKey;
    try {
      const res = await this.outcomeSvc
        .apiOutcomeParentOutcomeClaimKeyFinalizePost$Response({ parentOutcomeClaimKey, body: { comment: comments } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.finalize() failed with: ${error.message}` });
    }
  }
  
  async return(comments?: string) {
    const parentOutcomeClaimKey = this.outcomeKey;
    try {
      const res = await this.outcomeSvc
        .apiOutcomeParentOutcomeClaimKeyReturnPost$Response({ parentOutcomeClaimKey, body: { comment: comments } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.finalize() failed with: ${error.message}` });
    }
  }

  async cancelCheckpoint(comments?: string) {
    const parentOutcomeClaimKey = this.outcomeKey;
    try {
      const res = await this.outcomeSvc
        .apiOutcomeParentOutcomeClaimKeyCancelCheckpointPost$Response({ parentOutcomeClaimKey, body: { comment: comments } })
        .toPromise();

      if (res) {
        console.log(res);
      }
    } catch (error) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.cancelCheckpoint() failed with: ${error.message}` });
    }
  }

  reopenFinalizedOutcome$(parentOutcomeClaimKey: number, comments?: string) {
    if (!parentOutcomeClaimKey) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `no outcome claim key passed to ${this.constructor.name}.reopen` });
    }

    return this.outcomeSvc.apiOutcomeParentOutcomeClaimKeyReopenPost$Json({ parentOutcomeClaimKey, body: { comment: comments } });
  }

  cancelStartMonitoringPhase(parentMonitoringPhaseKey) {
    return this.outcomeSvc
      .apiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPost$Json({
        parentMonitoringPhaseKey,
      })
      .toPromise();
  }

  getFilteredOutcomes(obj: {
    claimTypeCode?: Array<string>, claimStatusCode?: Array<string>, outcomeStatusCode?: Array<string>, outcomeResultCode?: Array<string>,
    parentSiteKey?: Array<number>, fromDate?: string; toDate?: string; organization?: Array<string>; employee?: Array<string>,contractTypes?:Array<string>, biaIndicator?: boolean,
    pageNumber: number, pageSize: number, sortColumn: string, sortDirection: string, local: string;
  }) {
    const params = {
      claimTypeCode: obj.claimTypeCode,
      claimStatusCode: obj.claimStatusCode,
      outcomeStatusCode: obj.outcomeStatusCode,
      outcomeResultCode: obj.outcomeResultCode,
      parentSiteKey: obj.parentSiteKey,
      fromDate: obj.fromDate ? obj.fromDate : null,
      toDate: obj.toDate ? obj.toDate : null,
      organization: obj.organization,
      employee: obj.employee,
      contractTypes:obj.contractTypes,
      biaIndicator: obj.biaIndicator,
      pageNumber: obj.pageNumber, pageSize: obj.pageSize,
      sortColumn: obj.sortColumn, sortDirection: obj.sortDirection,
      local: obj.local
      // parentSiteKey: this.sites?.length > 0 ? this.sites[0] : this.globals.siteKey,
    };

    return this.outcomeSvc.apiOutcomeFilteredGet$Json(params);
  }

  getIntegrationPushRecords(parentOutcomeClaimKey?: number) {
    return this.outcomeSvc.apiOutcomePushIntegrationGet$Json$Response({ parentOutcomeClaimKey });
  }

  integrationPushRecords(pushCheckpoint?: string) {
    return this.outcomeSvc.apiOutcomePushIntegrationPost$Json$Response({ body: { checkpoints: pushCheckpoint } })
  }

  getIntegrationPullRecords(eapReferenceNumber?: number) {
    return this.outcomeSvc.apiOutcomePullIntegrationGet$Json$Response({ eapReferenceNumber });
  }

  integrationPullRecords(eapReferenceNumber?: string) {
    return this.outcomeSvc.apiOutcomePullIntegrationPost$Json$Response({ body: { eapReferenceNumbers: eapReferenceNumber }});
  }

  hasErrors() {
    return !!this.fg?.invalid;
  }

  errorList() {
    const errors: string[] = [];
    const fg = this.fg;

    if (fg) {
      Object.keys(fg.controls).forEach((key) => {
        if (fg.controls[key].invalid) {
          const errorMessage = errorMapFn(fg.controls[key] as UntypedFormControl, this.labels?.[key]);
          errors.push(errorMessage);
        }
      });
    }

    if (!this.hasCaseNotes)
      errors.push("Case Note is required when the Outcome Result is Unknown");

    return errors;
  }

  refreshValidation() {
    this?._fg?.markAllAsTouched();
  }

  hasJobStacking(ass){
    var json = JSON.parse(ass[0].assessmentJson);
    
    if(json.repeats.length > 1){
      return json.repeats.every((a) => 
        {
           return  a.JobHoursNum < 20;
        });
      }
      return false;
  }
}
import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { FormsFns } from '@core/models/forms-fns.model';
import { dateBuilder } from '@core/models/date.model';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { DocumentsDataService } from '@shared/components/documents/services/documents-data.service';
import { BaseFormComponent } from '@shared/components/base-form/base-form.component';
import { BaseComponent } from '@shared/components/base/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { OutcomesDataService } from '@modules/outcomes/services/outcomes-data.service';
import { GuidedAssessmentService } from '@modules/guidedAssessment/services/guidedAssessment.service';

@Component({
  selector: 'cf2-file-upload-details',
  template: `
    <div class="document-panel flex-col">
      <div class="detail-row">
        <cf2-date-field-top
          [ctrl]="getFc('documentDate')"
          [label]="labels.documentDate"
          [required]="required.documentDate"
          [disabled]="disabled.documentDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></cf2-date-field-top>

        <ng-container *ngIf="showSectionCodeField">
          <ng-container *ngIf="categoryOptions$ | async as options">
            <cf2-select-field-top
              [ctrl]="getFc('documentSectionCode')"
              [label]="labels.documentSectionCode"
              [required]="required.documentSectionCode"
              [disabled]="disabled.documentSectionCode"
              [options]="options"
            ></cf2-select-field-top>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="typeOptions$ | async as options">
          <cf2-select-field-top
            [ctrl]="getFc('documentTypeCode')"
            [label]="labels.documentTypeCode"
            [required]="required.documentTypeCode"
            [disabled]="disabled.documentTypeCode"
            [options]="options"
          ></cf2-select-field-top>
        </ng-container>

        <ng-container *ngIf="numOfJobsOptions | async as options">
          <cf2-select-field-top
            [ctrl]="getFc('documentJobNum')"
            [label]="labels.documentJobNum"
            [required]="required.documentJobNum"
            [disabled]="disabled.documentJobNum"
            [options]="options"
          ></cf2-select-field-top>
        </ng-container>

        <!--<ng-container *ngIf="categoryCodeOptions$ | async as options">
          <cf2-select-field-top
            [ctrl]="getFc('documentCategoryCode')"
            [label]="labels.documentCategoryCode"
            [required]="required.documentCategoryCode"
            [disabled]="disabled.documentCategoryCode"
            [options]="options"
          ></cf2-select-field-top>
        </ng-container>

        <ng-container *ngIf="subcategoryCodeOptions$ | async as options">
          <cf2-select-field-top
            [ctrl]="getFc('documentSubCategoryCode')"
            [label]="labels.documentSubCategoryCode"
            [required]="required.documentSubCategoryCode"
            [disabled]="disabled.documentSubCategoryCode"
            [options]="options"
          ></cf2-select-field-top>
        </ng-container>-->
      </div>

      <div class="comments-row">
        <cf2-textarea-field-top
          [ctrl]="getFc('comment')"
          [fullWidth]="true"
          [label]="labels.comment"
          [required]="required.comment"
          [disabled]="false"
          [rows]="2"
        ></cf2-textarea-field-top>
      </div>

      <div class="file-uploader-row">
        <!-- for the upload box -->
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./file-upload-details.component.scss'],
})
export class FileUploadDetailsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  private _documentTypes: any;
  disabled: any;
  labels: any;
  required: any;
  @Input() showSectionCodeField = false;

  /* input fileupload fields definition */
  @Input() fields: { labels: any; required: any; fg: any; disabled: any };

  /* TODO: check if this field needs to be disabled when a default category is present */
  @Input() defaultCategory: string;

  // HTodo: OUTPUT formData one every form data change
  // EventEmitter(fg.value);
  /* subscribe-able file type options */
  categoryOptions$ = this.dataSvc.documentCategory$.pipe(tap((obs) => console.log('category options ', obs)));
  typeOptions$ = this.dataSvc.documentType$.pipe(tap((ops) => console.log('Type Options', ops)));
  jobStackingEnabled : boolean = (this.outcomeSvc.outcome?.jobStackIndicator !== null && this.outcomeSvc.outcome?.jobStackIndicator?.trim() !== '') ;
  numOfJobsOptions = this.getJobOptions();
  numOfJobs : Number | undefined;

  /*jobNumberOptions = description: "Outcome Claims and Evidence"
  ​​
  disabled: false
  ​​
  label: "Outcome Claims and Evidence"
  ​​
  value: "CLAIMMGMT"*/

  //categoryCodeOptions$ = this.dataSvc.documentCategoryCode$.pipe(tap((ops) => console.log('Category Options', ops)));
  //subcategoryCodeOptions$ = this.dataSvc.documentSubCategoryCode$.pipe(tap((ops) => console.log('SubCategory Options', ops)));
  public minDate = new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);

  /* the maximum allowed date for the document  */
  public maxDate = new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2]);
  getFc: any;

  constructor(private dataSvc: DocumentsDataService, private outcomeSvc : OutcomesDataService, private guidedAssessmentService: GuidedAssessmentService) {
    super();
  }

  ngOnInit(): void {
    // console.log(this.fields);
    // get fields from @Input() fields
    // this.fields = this.dataSvc.getDocumentFormFields()
    if (!this.fields) {
      throw new Error(
        'no fields set in file upload details, you have to initalize DoucmentForm, documentDataSvc.addDocumentForm, in view container component'
      );
    }

    const fileTypeSubscribe = (fg: UntypedFormGroup) =>
      fg.controls.documentSectionCode.valueChanges.subscribe((obs) => {
        const codeTable = this.dataSvc.getDocumentTypeByParent(obs) as Observable<any>;
        fg.controls.documentTypeCode.patchValue(null);
        this.typeOptions$ = codeTable
          ? codeTable.pipe(
            tap((res) => (this._documentTypes = res)),
            map((res) =>
              res.map((type) => ({ value: type.documentTypeCode, description: type.documentTypeDescription }))
            )
          )
          : of(null);
      });

    const valueCfg = FormsFns.formUpdateOpts;

    const { fg }: { fg: UntypedFormGroup } = this.fields;

    const sectionCode =
      this.defaultCategory && fg.value.documentSectionCode == null
        ? this.defaultCategory
        : fg.value.documentSectionCode;
    console.log(sectionCode);
    /* assign a default value to the category if one exists */

    fg.controls.documentSectionCode.patchValue(sectionCode);
    fg.controls.documentSectionCode.updateValueAndValidity(valueCfg);
    fg.updateValueAndValidity(valueCfg);

    this.getFc = FormsFns.getFc(fg);
    this.setTypeOptions(sectionCode);

    Object.keys(this.fields).forEach((key) => (this[key] = this.fields[key]));

    this.addSubscription(fileTypeSubscribe(fg));
  }

  setTypeOptions(code: string) {
    if (code) {
      const obs = this.dataSvc.getDocumentTypeByParent(code) as Observable<any>;
      this.typeOptions$ = obs.pipe(
        tap((res) => (this._documentTypes = res)),
        map((res) => res.map((type) => ({ value: type.documentTypeCode, description: type.documentTypeDescription })))
      );
    } else {
      this.typeOptions$ = of([]);
    }
  }

  fileTypeChangeSubscribe(fg: UntypedFormGroup) {
    return fg.controls.documentSectionCode.valueChanges.subscribe((obs) => {
      const codeTable = this.dataSvc.getDocumentTypeByParent(obs) as Observable<any>;
      fg.controls.documentTypeCode.patchValue(null);
      this.typeOptions$ = codeTable
        ? codeTable.pipe(
          tap((res) => (this._documentTypes = res)),
          map((res) =>
            res.map((type) => ({ value: type.documentTypeCode, description: type.documentTypeDescription }))
          )
        )
        : of(null);
    });
  }
  
  getJobOptions(): Observable<{ value: string; label: string; description: string; disabled: boolean }[]> {
      if(typeof this.guidedAssessmentService.assessmentData === 'undefined' || this.guidedAssessmentService.assessmentData === null){
        return this.guidedAssessmentService.GetOutcomeQuestionnaire(
          this.outcomeSvc.outcome?.parentOutcomeClaimKey,
          '',
          this.outcomeSvc.outcome
        ).pipe(
          map((data: any[]) => {
          let obj = JSON.parse(data[0].assessmentJson);

          if(!obj.repeats.every((a) => 
                {
                   return  a.JobHoursNum <= 20;
                })){
                  return;
              }

          var li : { value: string; label: string; description: string; disabled: boolean }[] = []  

          for (let i = 1; i <= obj.repeatCount; i++) {
            li.push({
              value: `${i}`,
              label: `Job ${i}`,
              description: `Job ${i}`,
              disabled: false
            });

           
          }
          return li;
       } )
      );
    }
  
    return new Observable();
} 
  

  ngOnChanges(changes) {
    // console.log(changes);
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
}

import { UntypedFormGroup } from '@angular/forms';
import { DocumentDto } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { TranslocoService } from '@ngneat/transloco';
export type DocumentsViewType = 'create' | 'edit' | 'view';

export type DocumentStoreKeysType = keyof Omit<DocumentDto, 'documentLinks'>;
export type DocumentStoreLabelsType = Record<DocumentStoreKeysType, string | boolean>;

export interface DocumentStoreComponentField extends FormsComponentField {
  key: DocumentStoreKeysType;
}

export const DOCUMENT_STORE: readonly DocumentStoreComponentField[] = [
  {
    key: 'documentDate',
    label: 'Document Date',
    type: 'date',
    required: true,
  },
  {
    key: 'documentSectionCode',
    label: 'Category',
    type: 'select',
    required: true,
  },
  {
    key: 'documentTypeCode',
    label: 'Document Type',
    type: 'select',
    required: true,
  },
  {
    key: 'documentJobNum',
    label: 'Job Num',
    type: 'select',
    required: true,
  },
  //{
  //  key: 'documentCategoryCode',
  //  label: 'Document Category',
  //  type: 'select',
  //  required: true,
  //},
  //{
  //   key: 'documentSubCategoryCode',
  //   label: 'Document SubCategory',
  //   type: 'select',
  //   required: true,
  //},
  {
    key: 'comment',
    label: 'Description',
    type: 'longString',
    required: true,
  },
];

export class DocumentForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: DocumentStoreLabelsType;
    required: DocumentStoreLabelsType;
    disabled: DocumentStoreLabelsType;
  };
  get fields() {
    return this._fields;
  }

  constructor(fb: RxFormBuilder, tl : TranslocoService, opts?: { value?: any }) {
    super(fb, tl);
    const config = DOCUMENT_STORE;
    this._fields = this.makeFields([...config]) as any;
    // console.log(this._fields);
  }
}

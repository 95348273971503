import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { LinkDocumentsDialogComponent } from '@shared/components/documents/link-documents-dialog/link-documents-dialog.component';
import { DocumentsDataService } from '@shared/components/documents/services/documents-data.service';
import { Documents } from '@shared/components/documents/models/documents.model';
import { DocumentsViewType } from '@shared/components/documents/models/document-form.model';
import { DocumentsListConfig } from '@shared/components/documents/documents-list/documents-list.component';
import { DocumentDto } from '@api/models';
import { tap } from 'rxjs/operators';

import { parseDateFormattedFromDate, getToday } from '@shared/models/date-formats.model';
import * as R from 'remeda';
import { LoggingService } from '@core/services/logging.service';
import { BaseComponent } from '@shared/components/base/base.component';
import {
  ModalService,
  CONFIRM_DOCUMENTS_DELETE_DIALOG,
  CANNOT_DELETE_OUTCOME_DOCUMENTS,
} from '@shared/services/modal.service';
import { disableCtrlOps, editDisabled } from '@modules/expenditures/services/expenditures-data.service';
import { OutcomesDataService } from '@modules/outcomes/services/outcomes-data.service';
import { Globals } from 'src/app/globals';
import { DIALOGS } from 'src/app/core/models/dialogs.model';

import { TranslocoService } from "@ngneat/transloco";
/**
 * usage:
 * <cf2-documents-card [categoryCode]="categoryCode">
 */

@Component({
  selector: 'cf2-documents-card',
  template: `
    <div class="cf2-documents-card cf2-multiple-card" *ngIf="isReady; else loading">
      <div>
        <ng-container *ngIf="(this.hasReachedDocLimit || dataSub.value.length >=3)  && (this.categoryCode === 'EXPENDITURE')">
          <center>
            <span class="invalid-type-msg">
              Only up to 3 documents can be attached to an expenditure
            </span>
          </center>
        </ng-container>
        <ng-container *ngIf="(this.hasReachedDocLimit || dataSub.value.length >=3)  && (this.categoryCode === 'CLAIMMGMT')">
          <center>
            <span class="invalid-type-msg">
              Only up to  3 documents can be attached to a checkpoint
            </span>
          </center>
        </ng-container>
      </div>
      <div class="action-buttons">
        <ng-container *transloco="let t">
          <!-- Link Documents Button -->
          <cf2-button (callback)="openLinkableModal()" [disabled]="isReadOnly('linkDoc') || dataSub.value.length >=3">{{ t('LINK DOCUMENTS') }}</cf2-button>
          <!-- Add Document Button -->
          <!--<cf2-button (callback)="addDocumentForm()" [disabled]="isReadOnly('addDoc') || this.hasReachedDocLimit || (this.hasReachedDocLimit && dataSub.value.length >=3)">{{ t('ADD DOCUMENT') }}</cf2-button> -->
          <cf2-button (callback)="addDocumentForm()" [disabled]="isReadOnly('addDoc') || dataSub.value.length >=3">{{ t('ADD DOCUMENT') }}</cf2-button> 
        </ng-container>
      </div>

      <div class="list-block flex-row">
        <ng-container *ngIf="dataSub | async as data">
          <cf2-documents-list
            [config]="documentListConfig"
            [isReady]="isReady"
            [caseKey]="caseKey"
            [data]="data"
            [filteredData]="data"
            [isReadOnly]="isReadOnly('addDoc')"
            (selectedDocument)="showEditForm($event)"
            (delete)="canDelete($event)"
          >
          </cf2-documents-list>
        </ng-container>
      </div>
      <div class="edit-block" *ngIf="showDocumentForm">
        <cf2-file-upload-details [fields]="fields" [showSectionCodeField]="showSectionCode">
          <cf2-upload-box
            (fileBlobsUploaded)="addFile($event)"
            [displayName]="displayName"
            [files]="files"
            [date]="docDate"
            [fileType]="fileType"
            [noFiles]="invalidFiles"
          ></cf2-upload-box>

          <!--         [noFiles]="invalidFiles"
          [files]="files"-->
        </cf2-file-upload-details>
        <div class="action-buttons">
          <ng-container *transloco="let t">
            <!-- Cancel Button -->
            <cf2-button (callback)="cancelNewDocument()">{{ t('Cancel') }}</cf2-button>
            <!-- Add/Update -->
            <!--<cf2-button *ngIf="!editMode" (callback)="addNewDocument()" 
                [disabled]="isReadOnly('addDoc') || (this.hasReachedDocLimit && dataSub.value.length >=3)">{{ t('Add') }}</cf2-button>-->
            <cf2-button *ngIf="!editMode" (callback)="addNewDocument()" 
                [disabled]="isReadOnly('addDoc') ||  dataSub.value.length >=3">{{ t('Add') }}</cf2-button>
            <cf2-button *ngIf="editMode" [disabled]="!canEdit" (callback)="updateDocument()">{{ t('Update') }}</cf2-button>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-template #loading>
      <div class="loading">Documents Loading...</div>
    </ng-template>
  `,
  styleUrls: ['./documents-card.component.scss'],
})
export class DocumentsCardComponent extends BaseComponent implements OnInit, OnDestroy {
  isReady = false;

  @Input() viewType: DocumentsViewType;

  //TODO find moduleCode
  @Input() categoryCode: 'EXPENDITURE' | 'CLAIMMGMT';

  _fieldsToDisableEnable: disableCtrlOps[] = [];
  @Input() set fieldsToDisableEnable(value: disableCtrlOps[]) {
    this._fieldsToDisableEnable = value;
  }

  @Output() onDocumentUpdated: string;


  translocoService: TranslocoService;


  addDocumentButtonText: string;
  linkDocumentsButtonText: string;
  
  cancelButtonText: string;
  addButtonText: string;
  updateButtonText: string;
  DocumentsLoadingText = "Documents Loading...";

  fields: any;

  files: any;
  hasReachedDocLimit = false;
  DOC_UPLOAD_LIMIT = 3;
 JOBSTACK_UPLOAD_LIMIT = 15;

  invalidFiles = false;

  caseKey: number;

  displayName: string = '';
  fileType: any;
  fileUpdated = false;
  docDate: any;

  documents: any;
  showSectionCode = false;

  fg: UntypedFormGroup;

  dataSub: BehaviorSubject<Documents[]>;

  showDocumentForm = false;

  editMode = false;

  temporaryKey = 0;
  canEdit = false;

  documentListConfig: DocumentsListConfig = {
    showDeleteIcon: true,
  };

  isReadOnly(field = null) {
    if (this.categoryCode === 'CLAIMMGMT') return this.outcomeSvc.isReadOnly;
    else if (this.categoryCode === 'EXPENDITURE') return editDisabled(field, this._fieldsToDisableEnable);
    else return false;
  }



  constructor(
    private dataSvc: DocumentsDataService,
    private logSvc: LoggingService,
    private modalSvc: ModalService,
    public outcomeSvc: OutcomesDataService,
  ) {
    super();
    this.displayName = this.dataSvc.getDisplayName();
  }
  ngOnInit() {
    this.caseKey = this.dataSvc.getCaseKey();
    if (!this.categoryCode) {
      throw new Error(
        `${this.constructor.name} Category code is not found. Set in <cf2-documents-card [categoryCode]='categoryCode'>`
      );
      return;
    }

    if (!this.dataSvc.documentsViewType) {
      throw new Error(
        `${this.constructor.name} document view type is not set. Use DocumentDataService.initDocumentCard to set`
      );
    }

    // set parent module code
    this.dataSvc.parentCategoryCode = this.categoryCode;

    if (this.dataSvc.documentsViewType === 'view' || this.dataSvc.documentsViewType === 'edit') {
      if (this.categoryCode === 'EXPENDITURE') {
        const expenditureKey = this.dataSvc.expenditureKey;
        if (!expenditureKey) {
          throw new Error(`${this.constructor.name} Expenditure key is not found`);
          return;
        }

        this.addSubscription(
          this.dataSvc.getAllExpenditureDocuments(expenditureKey).subscribe(
            (docs) => {
              // console.log('ExpenditureKeyDocuments', docs);
              this.processResponse(docs);
              this.hasReachedDocLimit = docs.length >= this.DOC_UPLOAD_LIMIT;
            },
            (err) => {
              this.logSvc.logError({
                lvl: 'ERROR',
                mssg: 'Error retriving expenditure documents',
              });
              console.log(err);
            }
          )
        );
      } else if (this.categoryCode === 'CLAIMMGMT') {
        const parentOutcomeClaimKey = this.dataSvc.outcomeClaimKey;
        if (!parentOutcomeClaimKey) {
          throw new Error(`${this.constructor.name} outcomeClaimKey is not set`);
          return;
        }

        this.addSubscription(
          this.dataSvc.getAllOutcomeDocuments(parentOutcomeClaimKey).subscribe(
            (docs) => {
              this.processResponse(docs);
            },
            (err) => {
              this.logSvc.logError({
                lvl: 'ERROR',
                mssg: 'Error retriving expenditure documents',
              });
              console.log(err);
            }
          )
        );
        this.addSubscription(
          this.outcomeSvc.assessments$.subscribe(
            (ass) => {
              var json = JSON.parse(ass[0].assessmentJson);

              if(json.repeats.length > 1){
                var hours = json.repeats.reduce((a, b) => 
                  {
                     return  a+b.JobHoursNum;
                  }, 0);

                this.hasReachedDocLimit = hours >= this.JOBSTACK_UPLOAD_LIMIT;
              }
            },
            (err) => {
              this.logSvc.logError({
                lvl: 'ERROR',
                mssg: 'Error retriving expenditure documents',
              });
              console.log(err);
            }
          )
        );


      } else if (this.categoryCode === 'EMPLOYMENT') {
        const parentEmployerPositionReferralKey = this.dataSvc.employerPostionReferralKey;
        if (!parentEmployerPositionReferralKey) {
          throw new Error(`${this.constructor.name} parentEmployerPositionReferralKey is not set`);
          return;
        }

        this.addSubscription(
          this.dataSvc.getAllEmploymentDocuments(parentEmployerPositionReferralKey).subscribe(
            (docs) => {
              console.log('Employment Docs', docs);
              this.processResponse(docs);
            },
            (err) => {
              this.logSvc.logError({
                lvl: 'ERROR',
                mssg: 'Error retriving employment documents',
              });
              console.log(err);
            }
          )
        );
      }

      // create view
    } else {
      this.isReady = true;
    }

    // documents list table data
    this.dataSub = this.dataSvc.documentsListDataSub$;
    this.dataSub.subscribe((res) => R.noop());
    this.dataSvc.documentCategory$.subscribe((data) => (this.dataSvc.documentCategoryList = data));
    this.dataSvc.documentType$.subscribe((data) => (this.dataSvc.documentTypeList = data));
    this.dataSvc.invalidFiles$.subscribe((value) => (this.invalidFiles = value));
  }

  generateKey() {
    this.temporaryKey = this.temporaryKey + 0.001;
    return this.temporaryKey;
  }

  initDocForm(documentValue) {
    this.dataSvc.initDocumentForm(documentValue);
    this.fields = this.dataSvc.getDocumentFormFields();
    this.fg = this.fields.fg;

    this.fg.get('documentTypeCode').valueChanges.subscribe((documentTypeCode) => {
      this.fileType = this.dataSvc.getDocumentTypeDescription(documentTypeCode);
    });
    this.fg
      .get('documentDate')
      .valueChanges // .pipe(tap((val) => console.log(new Date(val))))
      .subscribe((val) => (this.docDate = parseDateFormattedFromDate(val)));

    this.showDocumentForm = true;
  }

  showEditForm(documentKey) {
    // if same document is selected the don't update the form
    if (this.dataSvc.selectedDocumentKey === documentKey) return;
    // hide existing document form, sanitize and then create form fields with values
    this.showDocumentForm = false;
    this.dataSvc.sanitizeDocumentForm();

    this.editMode = true;
    this.dataSvc.selectedDocumentKey = documentKey;

    const documentData = this.dataSvc.getDocumentDataByKey(documentKey);
    this.canEdit = typeof documentData.document.isLink === 'undefined' || !documentData.document.isLink;

    // set fileType
    this.fileType = documentData?.document?.documentTypeDescription
      ? documentData.document.documentTypeDescription
      : '';

    if (documentData.files) {
      this.files = documentData.files;
      this.invalidFiles = false;
    } else {
      this.files = [];
      this.invalidFiles = true;
    }

    // DOCTODO: missing documentDateFormmated
    const documentFormValue: Partial<DocumentDto> = {
      comment: documentData.document.comment,
      documentDate: documentData.document.documentDateFormatted,
      documentTypeCode: documentData.document.documentTypeCode,
      documentSectionCode: this.categoryCode,
      //documentCategoryCode: documentData.document.documentCategoryCode,
      //documentSubCategoryCode: documentData.document.documentSubCategoryCode,
    };

    setTimeout(() => this.initDocForm(documentFormValue), 100);
  }

  async addDocumentForm() {
    // if (this.showDocumentForm) return;
    // if no default module is set then show select section code field in the form
    if (!this.categoryCode) this.showSectionCode = true;
    

    this.addDocumentFormContinue()
  }

  addDocumentFormContinue() {

    // hide existing form
    this.showDocumentForm = false;
    this.dataSvc.sanitizeDocumentForm();
    // reset selected Document
    this.dataSvc.selectedDocumentKey = null;
    this.editMode = false;

    // re-initialize files
    this.files = [];
    this.fileType = '';
    this.fileUpdated = false;

    const documentFormValue: Partial<DocumentDto> = {
      documentSectionCode: this.categoryCode,
      documentDate: getToday(),
    };

      //setTimeout(() =>
      this.initDocForm(documentFormValue);
     //, 1);
  }


  

  cancelNewDocument() {
    this.files = [];
    this.fileType = '';
    this.fileUpdated = false;
    this.editMode = false;
    this.showDocumentForm = false;
    this.dataSvc.sanitizeDocumentForm();
  }

  addNewDocument() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    if (!this.files || this.files.length < 1) {
      this.invalidFiles = true;
      this.fg.markAllAsTouched();
      console.log('Invalid files data');
      return;
    }

    this.dataSvc.addDocumentData({
      tempKey: this.generateKey(),
      files: this.files,
      document: this.fg.value,
    });

    this.dataSvc.sanitizeDocumentForm();
    this.showDocumentForm = false;

    // update document list component
    this.dataSvc.setDocumentsListDataSource(this.dataSvc.getDocumentsData());
  }

  updateDocument() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    if (!this.files || this.files.length < 1) {
      this.invalidFiles = true;
      this.fg.markAllAsTouched();
      console.log('Invalid files data');
      return;
    }

    // If file is replaced
    if (this.fileUpdated) {
      this.dataSvc.documentKeyFileChanged({
        tempKey: this.dataSvc.selectedDocumentKey,
        files: this.files,
      });

      this.fileUpdated = false;
    }

    this.dataSvc.updateDocumentData({
      tempKey: this.dataSvc.selectedDocumentKey,
      files: this.files,
      document: this.fg.value,
    });

    this.dataSvc.sanitizeDocumentForm();
    this.editMode = false;
    this.showDocumentForm = false;

    // update documents list component
    this.dataSvc.setDocumentsListDataSource(this.dataSvc.getDocumentsData());
  }

  canDelete(key: number) {
    console.log(this.dataSvc.outcomeClaims);
    console.log(this.dataSvc.selectedOutcomeClaimKey);
    let canDeleteDocument = true;
    // check outcome claim status before deleting the document
    if (this.categoryCode === 'CLAIMMGMT') {
      if (this.dataSvc.selectedOutcomeClaimKey && this.dataSvc.outcomeClaims?.length > 0) {
        const outcome = R.find(
          this.dataSvc.outcomeClaims,
          (o) => o.parentOutcomeClaimKey === this.dataSvc.selectedOutcomeClaimKey
        );
        // ONly allow to delete/unlink document if outcome claim status is
        if (!(outcome?.outcomeStatusCode === 'INPR' || outcome?.outcomeStatusCode === 'RETN')) {
          canDeleteDocument = false;

          this.modalSvc.openDialog({
            data: CANNOT_DELETE_OUTCOME_DOCUMENTS,
          });
        }
      } else {
        this.logSvc.logError({
          lvl: 'ERROR',
          mssg: 'Outcomeclaims or selectedOutcomeKey not set',
        });
      }
    }

    canDeleteDocument ? this.deleteDocument(key) : '';
  }

  deleteDocument(documentKey: number) {
    const confirm_documents = CONFIRM_DOCUMENTS_DELETE_DIALOG;

    const doc = this.dataSvc.getDocumentDataByKey(documentKey);

    // set unlink title, if its a link document
    if (doc?.document?.isLink) {
      confirm_documents.title = 'Unlink Document';
      confirm_documents.message = 'Are you sure, you want to unlink document?';
    }

    // Show confirm modal
    const confirmDeleteDialog = this.modalSvc.openDialog({
      data: confirm_documents,
    });

    confirmDeleteDialog.afterClosed().subscribe((res) => {
      if (res && res === true) {
        this.dataSvc.deleteDocumentData(documentKey);
        // update document list component
        this.dataSvc.setDocumentsListDataSource(this.dataSvc.getDocumentsData());
      }
    });
  }

  addFile(files: any) {
    if (files && files.length > 0) {
      this.invalidFiles = false;
      this.dataSvc.invalidFiles = false;
    } else {
      this.invalidFiles = true;
      this.dataSvc.invalidFiles = true;
    }

    this.fileUpdated = true;
    this.files = files;
  }

  openLinkableModal() {
    //  Get linked documents and pass to custom dialog
    const linkedDocuments = this.dataSvc.getLinkedDocuments();
    const modalRef = this.modalSvc.openCustomDialog(LinkDocumentsDialogComponent, {
      data: {
        linkedDocuments,
      },
      panelClass: 'link-documents-dialog',
    });

    modalRef.afterClosed().subscribe((documents: Documents[]) => {
      if (documents.length > 0) {
        // add / update link documents
        this.dataSvc.addLinkDocuments(documents);
        // show in documents list table
        this.dataSvc.setDocumentsListDataSource(this.dataSvc.getDocumentsData());
      }
    });
  }

  processResponse(docs) {
    const documents: Documents[] = this.dataSvc.mapDocuments(docs);
    const documentsData = this.dataSvc.mapDocumentsData(documents);

    this.dataSvc.setDocumentsData(documentsData);
    this.dataSvc.setDocumentsListDataSource(this.dataSvc.getDocumentsData());

    this.isReady = true;
  }

  ngOnDestroy() {
    this.unsubscribe();
    // clear document form data and state
    this.dataSvc.sanitizeData();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { InquiryService, ClientSiteService, OutcomeService } from '@api/services';
import {
  TemplateModel,
  AssessmentDataModel,
  InquiryModel,
  ProcessResponse,
  OutcomeClaimModel,
  ClientSiteAssessmentInquiryModel,
  TemplateText,
} from '@api/models';
import { AssessmentService } from 'src/app/api/services/assessment.service';
import { take, tap } from 'rxjs/operators';

export const Translations = {
  ok: {
    en: 'OK',
    fr: 'Bien',
  },
  survey_complete_message: {
    en: 'Thank you! We have passed your details on to the team. Someone will be in touch within 2 business days.',
    fr:
      'Merci! Nous avons transmis vos cordonées à l’équipe. Quelqu’un sera en contact avec vous dans les 2 jours ouvrables.',
  },
  data_save_error: {
    en: 'Data saving error. Something went wrong',
    fr: 'Erreur d’enregistrement des données. On a eu un problème.',
  },
  prev_page: {
    en: 'Previous Page',
    fr: 'Précédant',
  },
  next_page: {
    en: 'Next Page',
    fr: 'Suivant',
  },
  submit: {
    en: 'Submit',
    fr: 'Soumettre',
  },
  save_assessment: {
    en: 'Save Assessment',
    fr: 'Save Assessment',
  },
  exit: {
    en: 'Exit',
    fr: 'Sortie',
  },
  summary: {
    en: 'Summary',
    fr: 'Un sommaire',
  },
  eligibility: {
    en: 'Eligibility',
    fr: 'L’éligibilité',
  },
  demographics: {
    en: 'Demographics',
    fr: 'La démographie',
  },
  consent: {
    en: 'Consent',
    fr: 'Je consens',
  },
};

@Injectable({
  providedIn: 'root',
})
export class GuidedAssessmentService {
  public assessmentType = 'PRECAT';
  public assessmentTitle = 'Inquiry Survey';
  public templateKey = -1;
  public parentRecordKey = -1;
  public statusCode = '';
  public statusDescription = '';
  public statusDescriptionFr = '';
  public clientFacing = false;

  public returnRoute = '';
  public siteCode = '';

  public languageCode = 'en';
  public parentAssessmentDataKey = -1;
  public inquiry: InquiryModel;
  public template: TemplateModel;
  public assessmentData: string;
  public assessments: AssessmentDataModel[];
  public unlockRecord = true;

  public ready$ = new BehaviorSubject<boolean>(null);

  outcome: OutcomeClaimModel;

  constructor(
    private assessmentService: AssessmentService,
    private outcomeService: OutcomeService,
    private inquiryService: InquiryService,
    private clientSiteService: ClientSiteService
  ) {}

  public loadAssessmentFromData(assessment: AssessmentDataModel, returnRoute, outcome?: OutcomeClaimModel) {
    this.outcome = outcome;
    this.assessmentType = 'OUTCOME';
    this.assessmentTitle = 'Outcome Questionnaire';
    this.parentRecordKey = assessment.fkrecordKey;
    this.returnRoute = returnRoute;
    this.statusCode = '';
    this.statusDescription = '';
    this.statusDescriptionFr = '';

    // cleanup data
    this.assessmentData = null;
    this.assessments = null;
    this.ready$ = new BehaviorSubject<boolean>(null);
    this.parentAssessmentDataKey = -1;
    forkJoin([
      // this.outcomeService.apiInquiryParentInquiryKeyGet$Json(
      //   { parentInquiryKey: parentRecordKey }),
      this.assessmentService.apiAssessmentConfigurationTemplateTypeCodeGet$Json({
        templateTypeCode: this.assessmentType,
      }),
      this.outcomeService.apiOutcomeParentOutcomeClaimKeyAssessmentsGet$Json({
        parentOutcomeClaimKey: assessment.fkrecordKey,
      }),
    ])
      .pipe(take(1))
      .subscribe((result) => {
        //this.inquiry = result[0];
        this.template = result[0] as TemplateModel;
        this.assessments = result[1];

        this.templateKey = this.template.templateKey;

        if (this.assessments !== undefined && this.assessments !== null && this.assessments.length > 0) {
          this.parentAssessmentDataKey = this.assessments[0].parentAssessmentDataKey;
          this.assessmentData = this.assessments[0].assessmentJson;

          this.statusCode = this.assessments[0].assessmentStatusCode;
          this.statusDescription = this.assessments[0].assessmentStatusDescription;
          this.statusDescriptionFr = this.assessments[0].assessmentStatusDescriptionFr;
        }

        // display assessment!
        this.ready$.next(true);
      });
  }
  public loadAssessmentConfiguration(
    assessmentType: string,
    parentRecordKey: number,
    returnRoute: string,
    outcome?: OutcomeClaimModel,
    siteCode: string = '',
    lang = 'en'
  ): void {
    this.outcome = outcome;

    this.assessmentType = assessmentType;
    if (this.assessmentType === 'OUTCOME') this.assessmentTitle = 'Outcome Questionnaire';
    this.parentRecordKey = parentRecordKey;
    this.returnRoute = returnRoute;
    this.siteCode = siteCode;
    this.statusCode = '';
    this.statusDescription = '';
    this.statusDescriptionFr = '';
    this.languageCode = lang ? lang : 'en';

    // cleanup data
    this.assessmentData = null;
    this.assessments = null;
    this.ready$ = new BehaviorSubject<boolean>(null);
    this.parentAssessmentDataKey = -1;

    if (
      this.assessmentType !== null &&
      this.assessmentType.length > 0 &&
      this.parentRecordKey !== null &&
      this.parentRecordKey > 0
    ) {
      // load assessment data (if it exists)
      if (this.assessmentType !== null && assessmentType === 'PRECAT' && this.clientFacing === false) {
        // get the inquiry record & the configuration
        forkJoin([
          this.inquiryService.apiInquiryParentInquiryKeyGet$Json({ parentInquiryKey: this.parentRecordKey }),
          this.assessmentService.apiAssessmentConfigurationTemplateTypeCodeGet$Json({
            templateTypeCode: this.assessmentType,
          }),
          this.inquiryService.apiInquiryParentInquiryKeyAssessmentsGet$Json({ parentInquiryKey: this.parentRecordKey }),
        ])
          .pipe(take(1))
          .subscribe((result) => {
            this.inquiry = result[0];
            this.template = result[1] as TemplateModel;
            this.assessments = result[2];

            this.templateKey = this.template.templateKey;

            if (this.assessments !== undefined && this.assessments !== null && this.assessments.length > 0) {
              this.parentAssessmentDataKey = this.assessments[0].parentAssessmentDataKey;
              this.assessmentData = this.assessments[0].assessmentJson;

              this.statusCode = this.assessments[0].assessmentStatusCode;
              this.statusDescription = this.assessments[0].assessmentStatusDescription;
              this.statusDescriptionFr = this.assessments[0].assessmentStatusDescriptionFr;
            }

            // display assessment!
            this.ready$.next(true);
          });
      } else if (this.assessmentType !== null && assessmentType === 'OUTCOME' && this.clientFacing === false) {
        // get the inquiry record & the configuration
        forkJoin([
          // this.outcomeService.apiInquiryParentInquiryKeyGet$Json(
          //   { parentInquiryKey: parentRecordKey }),
          this.assessmentService.apiAssessmentConfigurationTemplateTypeCodeGet$Json({
            templateTypeCode: this.assessmentType,
          }),
            this.outcomeService.apiOutcomeParentOutcomeClaimKeyPreviousAssessmentsGet$Json({
            parentOutcomeClaimKey: parentRecordKey,
          }),
        ])
          .pipe(take(1))
          .subscribe((result) => {
            //this.inquiry = result[0];
            this.template = result[0] as TemplateModel;
            this.assessments = result[1];

            this.templateKey = this.template.templateKey;

            if (this.assessments !== undefined && this.assessments !== null && this.assessments.length > 0) {
              this.parentAssessmentDataKey = this.assessments[0].parentAssessmentDataKey;
              this.assessmentData = this.assessments[0].assessmentJson;

              this.statusCode = this.assessments[0].assessmentStatusCode;
              this.statusDescription = this.assessments[0].assessmentStatusDescription;
              this.statusDescriptionFr = this.assessments[0].assessmentStatusDescriptionFr;
            }

            // display assessment!
            this.ready$.next(true);
          });
      } else if (this.assessmentType !== null && this.assessmentType.length > 0) {
        this.assessmentService
          .apiAssessmentConfigurationTemplateTypeCodeGet$Json({ templateTypeCode: this.assessmentType })
          .pipe(take(1))

          .subscribe((result) => {
            this.templateKey = result.templateKey;
            this.template = result;
            this.ready$.next(true);
          });
      }
      // if parentRecordKey < 0 then its clientFacing anonymous assessment so use ClientSite Endpoint
    } else if (this.assessmentType !== null && this.assessmentType.length > 0) {
      this.getClientSiteAssessmentConfiguration(assessmentType).subscribe((result) => {
        this.templateKey = result.templateKey;
        this.template = result;
        this.ready$.next(true);
      });
    }
  }

  public GetOutcomeQuestionnaire(
    parentRecordKey: number,
    returnRoute: string,
    outcome?: OutcomeClaimModel,
    siteCode: string = '',
    lang = 'en'
  ): Observable<Array<AssessmentDataModel>> {
    this.outcome = outcome;

     return this.outcomeService.apiOutcomeParentOutcomeClaimKeyPreviousAssessmentsGet$Json({
        parentOutcomeClaimKey: parentRecordKey,
      });
    }
  
  // use `keyof typeof {obj literal}` to get the type for @param key
  //  https://stackoverflow.com/a/41947225
  getTranslation(key: keyof typeof Translations | string) {
    // if key not found then return the  key
    if (!Translations[key]) {
      console.warn(`${this.constructor.name}.getTranslation(${key}): Translation not found for '${key}'`);
      return key;
    } else return this.languageCode === 'fr' ? Translations[key]['fr'] : Translations[key]['en'];
  }

  getTranslatedTextDescription(obj: TemplateText): string {
    return this.languageCode === 'fr' ? obj['textDescriptionFr'] : obj['textDescription'];
  }

  getClientSiteAssessmentConfiguration(templateTypeCode: string) {
    return this.clientSiteService.apiClientSiteConfigurationTemplateTypeCodeGet$Json({ templateTypeCode });
  }
  // save assessment data
  public clientFacingAssessment(data: ClientSiteAssessmentInquiryModel): Observable<ProcessResponse> {
    return this.clientSiteService.apiClientSitePost$Json({ body: data });
  }

  public saveAssessment(parentRecordKey: number, data: AssessmentDataModel): Observable<ProcessResponse> {
    if (this.assessmentType === 'OUTCOME') {
      //assessment does not exist, create a new one
      if (data.parentAssessmentDataKey === -1) {
        data.assessmentDataKey = 0;
        data.parentAssessmentDataKey = null;
      } else {
        //add a couple missing entries
        data.assessmentDataKey = data.parentAssessmentDataKey;
        data.fktableName = 'OutcomeClaim';
        data.fkschemaName = 'dbo';
        data.fkfieldName = 'ParentOutcomeClaimKey';
        data.fkrecordKey = parentRecordKey;
      }
      return this.outcomeService.apiOutcomeParentOutcomeClaimKeyAssessmentsPost$Json({
        parentOutcomeClaimKey: parentRecordKey,
        body: data,
      });
    }
    return this.inquiryService.apiInquiryParentInquiryKeyAssessmentsPost$Json({
      parentInquiryKey: parentRecordKey,
      body: data,
    });
  }
}
